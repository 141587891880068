import './src/styles/global.css';
import { loadableReady } from '@loadable/component';
import { hydrate } from 'react-dom';

// eslint-disable-next-line react/no-danger

export const registerServiceWorker = () => {
  if (typeof window !== 'undefined') {
    // Do magic
  }
};
export const onClientEntry = () => {
  if (process.env.ENABLE_LOCAL_SW) {
    registerServiceWorker();
  }
};
// export default registerServiceWorker;
export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    hydrate(element, container, callback);
  });
};
