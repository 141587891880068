exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/Team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-tech-talks-js": () => import("./../../../src/pages/TechTalks.js" /* webpackChunkName: "component---src-pages-tech-talks-js" */),
  "component---src-templates-tech-talks-js": () => import("./../../../src/templates/Tech-talks.js" /* webpackChunkName: "component---src-templates-tech-talks-js" */)
}

